import {Link, navigate} from 'gatsby'
import React, {useContext, useEffect, useState} from 'react'

import AddCart from '../images/svgs/addtocart.svg'
import BackSvg from '../images/svgs/back.svg'
import {CartContext} from '../components/gallery/cart/CartProvider'
import Img from 'gatsby-image'
import NewTab from '../images/svgs/newtab.svg'
import {ProductsContext} from '../components/gallery/products/ProductsProvider'
import {formatPrice} from '../components/shared/utils'
import {graphql} from 'gatsby'
import {useLayoutValue} from '../components/ui/layoutProvider'

const find = (id, skus) => {
  let stock = Object.values(skus).find((sku) => sku.id === id)
  console.log(JSON.stringify(stock))
  return stock.inventory.quantity
}

export default function PageTemplate({data}) {
  const {fetchInventory, skus} = useContext(ProductsContext)
  const sku = data.stripeSku
  const {add, toggle} = useContext(CartContext)
  const {state, dispatch} = useLayoutValue()
  const [stock, setStock] = useState('~')

  useEffect(() => {
    if (Object.keys(skus).length != 0 && skus.constructor === Object) {
      const stock = find(sku.id, skus)
      setStock(stock)
    }
    dispatch({
      type: 'SET_MENU',
      value: true,
    })
  }, [skus])
  return (
    <div className="flex flex-col bg-main ">
      <div className=" text-white flex flex-row justify-between w-full  bg-gray tracking-widest">
        <div className="bg-gray pt-20 mx-10 text-5xl md:text-7xl pt-20 leading-header">
          {sku.product.name}
        </div>
        <button
          className="m-2 mx-4 self-end"
          onClick={() => {
            navigate(-1)
          }}>
          <BackSvg className="h-8" />
        </button>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/3 w-full pl-10 pr-10 pt-10 ">
          <Img className="rounded" fluid={sku.localFiles[0].childImageSharp.fluid}></Img>
        </div>
        <div className="p-10 md:mt-10 md:p-0 flex flex-col">
          <div className="text-3xl">{sku.product.name}</div>
          <div className="border-b my-2 border-white"></div>
          <div className="md:text-2xl text-sm">_{sku.product.description}</div>
          <div className="border-b my-2 border-white"></div>
          <div className="text-black text-base italic">
            {sku.inventory.quantity === 0 ? 'out of stock' : `${stock} remaining`}{' '}
          </div>
          <div className="border-b my-2 border-white"></div>
          <div className="mt-2 font-mono">{formatPrice(sku.price, sku.currency)}</div>
          <Link
            to="/cart"
            className="font-bold text-sm my-2  hover:underline   font-medium"
            onClick={() => {
              add(sku.id)
              toggle(true)
            }}>
            Add To Cart <AddCart className="w-6 h-6 inline-block"></AddCart>
          </Link>

          <div className="border-b my-2 border-white"></div>
          <div className="md:text-sm hover:underline ">
            <a target="_blank" href={`${sku.localFiles[0].childImageSharp.original.src}`}>
              Large Image <NewTab className="h-4 w-4 inline-block" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
export const pageQuery = graphql`
  query ArtworkPageQuery($id: String) {
    stripeSku(id: {eq: $id}) {
      id
      currency
      price
      inventory {
        quantity
      }
      product {
        id
        name
        description
      }
      localFiles {
        childImageSharp {
          original {
            src
          }
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
